<!-- Form -->
@if (edit() || handleEdit()) {
  <form [formGroup]="giftForm()">
    <div class="flex items-center mt-6 mb-4 px-4 gap-4">
      <input [id]="'toMe-' + id" type="radio" formControlName="sentToMe" [value]="true"
             class="w-7 h-7 text-gray-700 ring-gray-200 focus:ring-gray-700 focus:ring-2">
      <label [for]="'toMe-' + id"
             class="ms-2 font-medium text-sm md:text-md">{{ (isCoupon() ? 'sentToMeCoupon' : 'sentToMe') | translate }}</label>
    </div>
    <div class="flex items-center gap-4 px-4">
      <input [id]="'notToMe-' + id" type="radio" formControlName="sentToMe" [value]="false"
             class="w-7 h-7 text-gray-700 ring-gray-200 focus:ring-gray-700 focus:ring-2">
      <label [for]="'notToMe-' + id"
             class="ms-2 font-medium text-sm md:text-md">{{ (isCoupon() ? 'sentToOtherCoupon' : 'sentToOther') | translate }}</label>
    </div>
    <p class="font-semibold text-xl mt-12 mb-6">{{ 'formatChoice' | translate }}</p>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <label [for]="'eTicket-' + id"
             class="flex items-start justify-between gap-4 px-6 py-8 rounded-lg"
             [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
        <p class="ms-2 font-medium text-md whitespace-pre-line">{{ 'formatETicket' | translate }}</p>
        <input [id]="'eTicket-' + id" type="radio" [value]="GiftFormat.eTicket" formControlName="format"
               class="w-8 h-8 text-100cielsGift-100 ring-gray-200 focus:ring-100cielsGift-100 focus:ring-2">
      </label>
      <label [for]="'mail-' + id" class="flex items-start justify-between gap-4 px-6 py-8 rounded-lg"
             [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
        <p
          class="ms-2 font-medium text-md whitespace-pre-line">{{ 'formatMail' | translate: { fee: siteService.price(productService.managementFee().id).price.toString() } }}</p>
        <input [id]="'mail-' + id" type="radio" [value]="GiftFormat.mail" formControlName="format"
               class="w-8 h-8 text-100cielsGift-100 ring-gray-200 focus:ring-100cielsGift-100 focus:ring-2">
      </label>
    </div>
    <div class="pt-4 ms-2 font-medium text-sm md:text-md">
      {{ 'requiredFields' | translate }}
    </div>
    <div class="relative">
      <input
        id="from"
        formControlName="from"
        class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
        [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
      <label for="from"
             class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
        {{ 'from' | translate }}
      </label>
    </div>
    @if (!giftForm().get('sentToMe')?.value && giftForm().get('format')?.value === GiftFormat.mail) {
      <div class="flex flex-col md:flex-row md:gap-4">
        <div class="relative">
          <input
            id="beneficiaryFirstName"
            formControlName="firstName"
            class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
            [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
          <label for="beneficiaryFirstName"
                 class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
            {{ 'beneficiaryFirstName' | translate }}
          </label>
        </div>
        <div class="relative">
          <input
            id="beneficiaryName"
            formControlName="lastName"
            class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
            [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
          <label for="beneficiaryName"
                 class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
            {{ 'beneficiaryName' | translate }}
          </label>
        </div>
      </div>
    }
    @if (displayAddressForm()) {
      <div class="relative">
        <input
          id="beneficiaryAddress"
          formControlName="address"
          class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
          [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
        <label for="beneficiaryAddress"
               class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
          {{ (sentToMe() ? 'myAddress' : 'beneficiaryAddress')| translate }}
        </label>
      </div>
      <div class="flex flex-col md:flex-row gap-4 mt-5">
        <div class="relative">
          <input
            id="beneficiaryZIP"
            formControlName="zip"
            class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
            [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
          <label for="beneficiaryZIP"
                 class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
            {{ (sentToMe() ? 'myZIP' : 'beneficiaryZIP')| translate }}
          </label>
        </div>
        <div class="relative">
          <input
            id="beneficiaryCity"
            formControlName="city"
            class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
            [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
          <label for="beneficiaryCity"
                 class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
            {{ (sentToMe() ? 'myCity' : 'beneficiaryCity')| translate }}
          </label>
        </div>
      </div>
    } @else {
      <div class="relative">
        <input
          id="beneficiaryEmail"
          formControlName="email"
          class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base"
          [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'">
        <label for="beneficiaryEmail"
               class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
          {{ (sentToMe() ? 'myEmail' : 'beneficiaryEmail')| translate }}
        </label>
      </div>
    }
    <div class="relative">
    <textarea id="beneficiaryMessage" formControlName="message" rows="3"
              class="px-4 pt-6 pb-2 rounded-lg border-none w-full mt-5 font-medium text-base resize-none"
              [ngClass]="theme() === 'gift' ? 'bg-white' : 'bg-beige'"></textarea>
      <label for="beneficiaryMessage"
             class="absolute text-xs text-gray-500 top-6 z-10 left-4 pt-1">
        {{ 'beneficiaryMessage' | translate }}
      </label>
    </div>
    @if (!giftForm().get('sentToMe')?.value) {
      <div class="bg-beige p-4 rounded-xl mt-4">
        <p class="font-medium text-sm md:text-base"> {{ 'sendingDate' | translate }} : </p>
        @if (giftForm().get('format')?.value === GiftFormat.mail) {
          <p class="font-medium text-xs md:text-xs"> {{ 'sendingInfo' | translate }} </p>
        }
        <app-date-picker [notBefore]="today" [selected]="shipDateAsDate()"
                         (selectedChange)="updateShipDate($event)"></app-date-picker>
      </div>
    }
    <button appHoverGradient
            type="button"
            [appProgressClick]="submit"
            [disabled]="giftForm().invalid || !canBeSubmitted()"
            class="flex items-center gap-3 mt-5 w-full justify-center bg-100ciels-300">
      {{ 'validate' | translate }}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor"
           class="w-6 h-6">
        <path d="m4.5 12.75 6 6 9-13.5"/>
      </svg>
    </button>
  </form>
}
