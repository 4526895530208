import {
  DatePipe,
  NgClass,
  NgOptimizedImage,
  ViewportScroller
} from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

import {
  GiftCartItem,
  GiftFormat
} from '../../../../core/model/cart/cart-item.model';
import { ProductType } from '../../../../core/model/product.model';
import { emulateClick } from '../core/a11y';
import { DatePickerComponent } from '../shared/date-picker/date-picker.component';
import { HoverGradientDirective } from '../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../shared/directives/progress-click.directive';
import { Product } from '../shared/model/product.model';
import { AuthService } from '../shared/services/auth.service';
import { ProductService } from '../shared/services/product.service';
import { SiteService } from '../shared/services/site.service';
import { EmailValidator } from '../shared/validators/validators';

export type GiftForm = {
  sentToMe: FormControl<boolean>;
  format: FormControl<GiftFormat>;
  from: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  address: FormControl<string>;
  zip: FormControl<string>;
  city: FormControl<string>;
  message: FormControl<string>;
  shipDate: FormControl<string>;
};

@Component({
  selector: 'app-gift-form',
  standalone: true,
  imports: [
    HoverGradientDirective,
    NgOptimizedImage,
    NgxTolgeeModule,
    RouterLink,
    ReactiveFormsModule,
    NgClass,
    ProgressClickDirective,
    DatePickerComponent,
    DatePipe
  ],
  templateUrl: './gift-form.component.html',
})
export class GiftFormComponent implements OnInit {
  readonly emulateClick = emulateClick;
  readonly GiftFormat = GiftFormat;
  readonly fieldRegex = /[^\x20-\x7E\x80-\xFF]/g;
  authService = inject(AuthService);
  siteService = inject(SiteService);
  productService = inject(ProductService);
  viewportScroller = inject(ViewportScroller);

  id = Math.random().toString(36).substring(2);

  editOnLoad = input(false);
  gift = input.required<GiftCartItem>();
  isCoupon = computed(() => {
    const product = this.productService.get(this.gift().product);
    return product instanceof Product && product.type === ProductType.GiftCertificate;

  });
  giftFormSubmitted = output<GiftCartItem>();
  canBeSubmitted = input<boolean>(true);
  theme = input<'gift' | 'checkout'>('gift');
  giftForm = computed(() => {
    const gift = this.gift();
    return new FormGroup<GiftForm>({
      sentToMe: new FormControl(gift.sentToMe, { nonNullable: true, validators: [ Validators.required ] }),
      format: new FormControl(gift.format, { nonNullable: true, validators: [ Validators.required ] }),
      from: new FormControl(gift.from, { nonNullable: true, validators: [ Validators.required ] }),
      firstName: new FormControl(gift.firstName, { nonNullable: true }),
      lastName: new FormControl(gift.lastName, { nonNullable: true }),
      email: new FormControl(gift.email, { nonNullable: true, validators: [ Validators.required, EmailValidator ] }),
      address: new FormControl(gift.address, { nonNullable: true }),
      zip: new FormControl(gift.zip, { nonNullable: true }),
      city: new FormControl(gift.city, { nonNullable: true }),
      message: new FormControl(gift.message, { nonNullable: true }),
      shipDate: new FormControl(gift.shipDate, { nonNullable: true }),
    });
  });
  shipDateAsDate = computed(() => {
    const form = this.giftForm();
    const date = form.get('shipDate')?.value ?? new Date().toISOString().slice(0, 10);
    const [ year, month, day ] = date.split('-').map(Number);
    return new Date(Date.UTC(year, month - 1, day))
  })
  today = new Date();
  displayAddressForm = signal<boolean>(false);
  sentToMe = signal<boolean>(false);
  edit = signal(false);
  handleEdit = input<boolean>(true);

  constructor() {
    effect(() => {
      if (this.editOnLoad()) {
        this.edit.set(true);
      }
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    this.initGiftForm();
  }

  /**
   * Submit gift form
   */
  submit = async (): Promise<void> => {
    this.giftFormSubmitted.emit(new GiftCartItem({ ...this.giftForm().getRawValue() }));
    this.edit.set(false);
    this.viewportScroller.scrollToPosition([ 0, 0 ]);
  }

  updateShipDate(shipDate: Date | undefined): void {
    if (!shipDate) {
      return;
    }
    const dateStr = new Date(Date.UTC(shipDate.getUTCFullYear(), shipDate.getUTCMonth(), shipDate.getUTCDate()))
      .toISOString()
      .slice(0, 10);
    this.giftForm().get('shipDate')?.setValue(dateStr);
  }

  /**
   * Initialise send to me and format form fields and listeners
   */
  private initGiftForm(): void {
    // On first load check if form has never filled
    if (!this.gift().from) {
      this.edit.set(true);
    }
    // Initialise Form
    const form = this.giftForm();
    // Initialise send to me
    const sentToMeControl = form.get('sentToMe')!;
    this.sendToMeChange(sentToMeControl.value, form);
    // Initialise format
    const formatControl = form.get('format')!;
    this.formatChange(formatControl.value, form);
    // Add sent to me form listener
    sentToMeControl.valueChanges.subscribe(sentToMe => {
      this.sendToMeChange(sentToMe, form);
    });
    // Add format form listener
    formatControl.valueChanges.subscribe(format => {
      this.formatChange(format, form);
    });
    const messageControl = form.get('message')!;
    // Add format form listener
    messageControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      messageControl.setValue(message, { emitEvent: false });
    });
    const fromControl = form.get('from')!;
    // Add format form listener
    fromControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      fromControl.setValue(message, { emitEvent: false });
    });
    const firstNameControl = form.get('firstName')!;
    // Add format form listener
    firstNameControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      firstNameControl.setValue(message, { emitEvent: false });
    });
    const lastNameControl = form.get('lastName')!;
    // Add format form listener
    lastNameControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      lastNameControl.setValue(message, { emitEvent: false });
    });
    const emailControl = form.get('email')!;
    // Add format form listener
    emailControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      emailControl.setValue(message, { emitEvent: false });
    });
    const addressControl = form.get('address')!;
    // Add format form listener
    addressControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      addressControl.setValue(message, { emitEvent: false });
    });
    const zipControl = form.get('zip')!;
    // Add format form listener
    zipControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      zipControl.setValue(message, { emitEvent: false });
    });
    const cityControl = form.get('city')!;
    // Add format form listener
    cityControl.valueChanges.subscribe(message => {
      // eslint-disable-next-line max-len
      message = message.replace(this.fieldRegex, '');
      cityControl.setValue(message, { emitEvent: false });
    });
  }

  /**
   * Actions when format value change
   * @param format The Format value
   * @param form The form group
   */
  private formatChange(format: GiftFormat, form: FormGroup<GiftForm>): void {
    this.displayAddressForm.set(format === GiftFormat.mail);
    const sentToMeControl = form.get('sentToMe')!;
    this.updateFormValues(sentToMeControl.value, format, form);
  }

  /**
   * Actions when send to me value change
   * @param sentToMe Send to me value
   * @param form The form group
   */
  private sendToMeChange(sentToMe: boolean, form: FormGroup<GiftForm>): void {
    this.sentToMe.set(sentToMe);
    if (sentToMe) {
      form.get('shipDate')?.removeValidators(Validators.required);
      form.get('shipDate')?.setValue('')
    } else {
      form.get('shipDate')?.addValidators(Validators.required);
      if (!form.get('shipDate')?.value) {
        form.get('shipDate')?.setValue(new Date().toISOString().slice(0, 10));
      }
    }
    const formatControl = form.get('format')!;
    this.updateFormValues(sentToMe, formatControl.value, form);
  }

  /**
   * Disable or enable firstname and last name validators and values
   * @param sendToMe Send to me value
   * @param format The format value
   * @param form The form group
   */
  private updateFormValues(sendToMe: boolean, format: GiftFormat, form: FormGroup): void {
    const user = this.authService.user();
    if (user && !form.get('from')?.value) {
      form.get('from')?.setValue(`${user.firstName} ${user.lastName}`.trim());
    }
    const cityControl = form.get('city');
    const zipControl = form.get('zip');
    const addressControl = form.get('address');
    const emailControl = form.get('email');
    if (format === GiftFormat.mail) {
      emailControl?.removeValidators(Validators.required);
      cityControl?.addValidators(Validators.required);
      zipControl?.addValidators(Validators.required);
      addressControl?.addValidators(Validators.required);
      emailControl?.setValue('');
      if (user && sendToMe) {
        addressControl?.setValue(user.address);
        zipControl?.setValue(user.zip);
        cityControl?.setValue(user.city);
      } else {
        addressControl?.setValue('');
        zipControl?.setValue('');
        cityControl?.setValue('');
      }
    } else {
      cityControl?.removeValidators(Validators.required);
      zipControl?.removeValidators(Validators.required);
      addressControl?.removeValidators(Validators.required);
      emailControl?.addValidators(Validators.required);
      emailControl?.setValue('');
      addressControl?.setValue('');
      zipControl?.setValue('');
      cityControl?.setValue('');
      if (user && sendToMe) {
        emailControl?.setValue(user.email);
      } else {
        emailControl?.setValue('');
      }

    }
    if (format === GiftFormat.mail && !sendToMe) {
      form.get('firstName')?.addValidators(Validators.required);
      form.get('lastName')?.addValidators(Validators.required);
    } else {
      form.get('firstName')?.removeValidators(Validators.required);
      form.get('lastName')?.removeValidators(Validators.required);
      form.get('firstName')?.setValue('');
      form.get('lastName')?.setValue('');
    }
  }
}
